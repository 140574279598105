import { OperationStatus, SignRequestMetadataSourceType, TransactionMetadataSourceType } from '@/lib/gql/graphql';

export namespace Operation {
  export const pendingStatuses = [OperationStatus.Presigning, OperationStatus.Voting, OperationStatus.Signing];

  export const finalStatuses = [
    OperationStatus.Broadcasting,
    OperationStatus.Submitted,
    OperationStatus.Cancelled,
    OperationStatus.Completed,
    OperationStatus.Expired,
    OperationStatus.Failed,
    OperationStatus.Rejected,
  ];

  export function isPending(status: OperationStatus): boolean {
    return pendingStatuses.includes(status);
  }

  /**
   * @returns  Whether the operation is in a final state.
   */
  export function isDone(status: OperationStatus): boolean {
    return finalStatuses.includes(status);
  }

  export function wasSuccessful(status: OperationStatus): boolean {
    return status === OperationStatus.Completed || status === OperationStatus.Submitted;
  }

  /**
   * Determines whether the operation has failed.
   * Does not include rejected or expired status.
   *
   * @returns whether the operation is OperationStatus.Failed
   */
  export function didFail(status: OperationStatus) {
    return status === OperationStatus.Failed;
  }

  /**
   * @returns whether the operation is in the state of being signed
   */
  export function isSigning(status: OperationStatus) {
    return [OperationStatus.Signing, OperationStatus.Presigning, OperationStatus.Broadcasting].includes(status);
  }

  export function getSourceDisplayName(
    source: SignRequestMetadataSourceType | TransactionMetadataSourceType | null | undefined,
  ): string {
    switch (source) {
      case SignRequestMetadataSourceType.Aave:
      case TransactionMetadataSourceType.Aave: {
        return 'Aave';
      }
      case SignRequestMetadataSourceType.Uniswap:
      case TransactionMetadataSourceType.Uniswap: {
        return 'Uniswap';
      }
      case SignRequestMetadataSourceType.WalletConnect:
      case TransactionMetadataSourceType.WalletConnect: {
        return 'Wallet Connect';
      }
      default: {
        return '';
      }
    }
  }

  export function getSourceFromSourceUrl(
    url: string | undefined,
    type: 'sign' | 'transaction',
  ): SignRequestMetadataSourceType | TransactionMetadataSourceType {
    if (!url) {
      return type === 'sign'
        ? SignRequestMetadataSourceType.WalletConnect
        : TransactionMetadataSourceType.WalletConnect;
    }
    if (url.includes('aave')) {
      return type === 'sign' ? SignRequestMetadataSourceType.Aave : TransactionMetadataSourceType.Aave;
    } else if (url.includes('uniswap')) {
      return type === 'sign' ? SignRequestMetadataSourceType.Uniswap : TransactionMetadataSourceType.Uniswap;
    } else {
      return type === 'sign'
        ? SignRequestMetadataSourceType.WalletConnect
        : TransactionMetadataSourceType.WalletConnect;
    }
  }
}
